<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <!-- Breadcrumb  -->

    <va-card class="row align--center mx-1">
      <va-card-content>
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('championships')" />
          <va-breadcrumbs-item :label="$t('menu.my_champ')" />
        </va-breadcrumbs>
      </va-card-content>
    </va-card>
    <!-- Content  -->
    <div class="mt-4">
      <va-card v-if="myChamps?.length > 0" class="va-table-responsive p-3">
        <va-card-content>
          <table class="va-table va-table--hoverable w-100">
            <thead>
              <tr>
                <th>{{ $t("id") }}</th>
                <th>{{ $t("client_name") }}</th>
                <th>{{ $t("championship_name") }}</th>
                <th>{{ $t("start_time") }}</th>
                <th>{{ $t("view_more") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(champ, index) in myChamps" :key="champ.id">
                <td>{{ index + offset + 1 }}</td>
                <td>{{ champ.champ?.creator?.name }}</td>
                <td>{{ champ.champ?.name }}</td>
                <td>{{ champ.champ?.suggested_start_time }}</td>
                <td>
                  <va-button
                    color="info"
                    flat
                    icon="eye"
                    @click="ViewMyChampDetails(champ.champ)"
                  ></va-button>
                </td>
              </tr>
            </tbody>
          </table>
          <va-pagination
            v-if="pages > 1"
            color="#0a4695"
            style="direction: ltr"
            class="mt-5"
            v-model="currentPage"
            :visible-pages="3"
            :pages="pages"
          />
        </va-card-content>
      </va-card>
      <div class="mx-3" v-else>
        <va-card
          class="row align--center"
          style="height: 35vh"
          stripe
          stripe-color="#1f1f62"
        >
          <va-card-content>
            <h1 class="text-center" style="font-size: 32px">
              {{ $t("no_champs") }}
              <va-icon class="material-icons" size="32px"> schedule </va-icon>
            </h1>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </va-inner-loading>
</template>

<script>
import { request, gql } from "graphql-request";

export default {
  data() {
    return {
      myChamps: [],
      myChampsCount: null,
      user_id: JSON.parse(localStorage["userData"])?.id,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: false,
    };
  },
  async mounted() {
    // Pagination Logic
    try {
      this.loading = true;
      const MY_CHAMPIONSHIPS_COUNT = gql`
        query ($id: Int) {
          myChamps: ChampEditorsCount(where: { user_id: $id }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        MY_CHAMPIONSHIPS_COUNT,
        { id: this.user_id }
      );
      this.myChampsCount = counterResponse.myChamps.count;
      this.pages = Math.ceil(this.myChampsCount / this.limit);
      this.getMyChampionships();
    } catch (error) {
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getMyChampionships() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const MY_CHAMPIONSHIPS = gql`
        query ($id: Int, $limit: Int, $offset: Int) {
          ChampEditors(where: { user_id: $id }) {
            champ(limit: $limit, offset: $offset) {
              id
              name
              logo
              suggested_start_time
              champ_type {
                name
                name_ar
              }
              creator {
                name
              }
              created
              updated
            }
          }
        }
      `;
      try {
        const response = await request(
          this.$store.state.appUrl,
          MY_CHAMPIONSHIPS,
          { limit: this.limit, offset: this.offset, id: this.user_id }
        );
        this.myChamps = response.ChampEditors;
        console.log(this.myChamps);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },
    ViewMyChampDetails(championship) {
      localStorage["routeName"] = this.$route.name;
      this.$router.push({
        name: "championshipDashboard",
        params: { id: championship.id, route: this.$route.name },
      });
    },
  },
  watch: {
    async currentPage() {
      await this.getMyChampionships();
    },
  },
};
</script>
